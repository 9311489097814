/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";

import {
  doFormatShortDate,
  doFormatTitle,
} from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import { ActionButton, HoverCard, HoverCardType } from "office-ui-fabric-react";
import SearchPreceptor from "gatsby-plugin-hfn-profile/components/SrcmModels/SearchPreceptor";
import SrcmSearchPage from "gatsby-plugin-hfn-profile/components/SrcmSearchPage";
import AbhyasiEntity from "gatsby-plugin-hfn-profile/components/SrcmEntities/AbhyasiEntity";
import ZoneAndZCEntity from "gatsby-plugin-hfn-profile/components/SrcmEntities/ZoneAndZCEntity";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import { get } from "sites-common/utils/lodash";

const RenderPreceptorStartDate = (abhyasiId) => (
  <div sx={{ bg: "#c0392b", color: "white", p: 2 }}>
    <SearchPreceptor
      model="preceptors"
      abhyasiId={abhyasiId}
      render={(p) => (
        <div>
          <img width="150" src={p.photo_url} alt="" />
          <div sx={{ fontSize: "1.2em", fontWeight: "700" }}>
            {doFormatTitle(p.name)}
          </div>
          <div>
            <font sx={{ fontWeight: "300" }}>prefect status:</font>{" "}
            {p.prefect_status}
          </div>
          <div>
            <font sx={{ fontWeight: "300" }}>sub status:</font>{" "}
            {p.prefect_sub_status}
          </div>
          <div>
            <font sx={{ fontWeight: "300" }}>prefect start date:</font>{" "}
            {doFormatShortDate(p.prefect_start_date)}
          </div>
          {p.prefect_end_date && (
            <div>
              <font sx={{ fontWeight: "300" }}>prefect end date:</font>{" "}
              {doFormatShortDate(p.prefect_end_date)}
            </div>
          )}
        </div>
      )}
    />
  </div>
);

const PreceptorDetails = ({ abhyasiId, display }) => {
  const plainCardProps = {
    onRenderPlainCard: RenderPreceptorStartDate,
    renderData: abhyasiId,
  };

  return (
    <HoverCard
      plainCardProps={plainCardProps}
      instantOpenOnClick
      type={HoverCardType.plain}
    >
      <ActionButton iconProps={{ iconName: "Photo" }} text={display} />
    </HoverCard>
  );
};

PreceptorDetails.propTypes = {
  abhyasiId: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
};

const colDefns = [
  [
    "Name",
    (r) => (
      <AbhyasiEntity
        abhyasiId={r.ref}
        display={doFormatTitle(r.name)}
        item={r}
      />
    ),
  ],
  ["Age", (r) => r.age || ""],
  ["Gender", (r) => r.gender || ""],
  [
    "City",
    (r) => (
      <div>
        {doFormatTitle(r.city)}{" "}
        <div sx={{ fontSize: "0.8em", opacity: 0.9 }}>
          {r.state ? r.state.name : ""} {r.country ? r.country.name : ""}
        </div>
      </div>
    ),
  ],
  ["Abhyasi Id", "ref"],
  [
    "Abhyas Stage",
    (r) => (
      <div>
        {r.is_prefect &&
          (r.abhyas_stage !== "TRAINER" ? (
            <div>
              {r.abhyas_stage}
              <br />
              <PreceptorDetails abhyasiId={r.ref} display="TRAINER_INACTIVE" />
            </div>
          ) : (
            <PreceptorDetails abhyasiId={r.ref} display={r.abhyas_stage} />
          ))}
        {!r.is_prefect && r.abhyas_stage}
      </div>
    ),
  ],
  [
    "Date of Joining",
    (r) => (r.date_of_joining ? doFormatShortDate(r.date_of_joining) : ""),
  ],
  [
    "Center",
    (r) =>
      r.srcm_group ? (
        <ZoneAndZCEntity
          centerId={r.srcm_group.id}
          display={r.srcm_group.name}
        />
      ) : (
        ""
      ),
  ],
];

const MeSearch = () => {
  const { srcmProfile } = useAuth();
  return (
    <div>
      <SrcmSearchPage
        reduxKey="me-search-abhyasis1"
        apiParams={{
          api: `/api/v2/abhyasis/`,
          client: "preceptorCandidateAppClient",
        }}
        defaultKey="name__icontains"
        defaultValue={get(srcmProfile, "name")}
        keyOptions={[
          { name: "ref", label: "Abhyasi Id" },
          { name: "name__icontains", label: "Name Contains" },
          { name: "email", label: "Email" },
        ]}
        colDefns={colDefns}
      />
    </div>
  );
};

export default MeSearch;
