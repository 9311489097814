import SearchModel from "./SearchModel";

const SearchPreceptor = ({ abhyasiId, render }) =>
  SearchModel({
    model: `preceptors`,
    methodParams: { ref: abhyasiId },
    onlyFirstRender: render,
    client: "preceptorCandidateAppClient",
  });

export default SearchPreceptor;
